<template>
  <div class="marketplace-listing" :class="{ 'mrkt-listing-height': isFollow }">
    <div class="clickable-item-hov">
      <a @click="goToListing(listing)">
        <div
          class="image position-relative d-flex align-items-center justify-content-center"
          :class="{ 'img-height': isFollow }"
        >
          <div class="tag-wrap position-absolute">
            <div class="tag d-flex align-items-center justify-content-center">Marketplace listing</div>
            <MatureTag v-if="isNsfw" class="mt-1" />
          </div>
          <div
            v-if="isOwner"
            class="clickable drop-down d-flex align-items-center justify-content-center"
            @click.stop.prevent="openDropDown"
          >
            <i class="ti-more-alt" />
          </div>

          <img loading="lazy" v-folder-card-image :src="resizeUpload(first(listing.visuals), '190h')" />
        </div>

        <div class="rest mt-1">
          <div class="name d-flex mt-1 clickable-item" :class="{ 'name-height': isFollow }">
            <span>
              {{ listing.title || '' }}
            </span>
          </div>
          <div class="description clickable-item">
            <span>
              {{ stripHtmlTags(listing.description || '') }}
            </span>
          </div>

          <div class="d-flex text-black align-items-center my-2">
            <div
              class="d-flex align-items-center"
              :style="listing?.base_price >= 9999 ? 'max-width: calc(100% - 75px)' : 'max-width: calc(100% - 68px)'"
              :class="{ 'base-content': listing?.base_price <= 9999 && listing.currency_type === 'gem' }"
            >
              <div
                :class="{
                  'text-color-username': usernameColor && !usernameColor.startsWith('#'),
                  'bold-username': usernameColor,
                }"
                :style="
                  !usernameColor
                    ? ''
                    : usernameColor.startsWith('#')
                    ? `color: ${usernameColor} !important`
                    : `background-image: ${usernameColor} !important`
                "
                class="user d-flex align-items-center"
              >
                @{{ listing?.seller?.username }}
              </div>
              <ProfileBadge :badges="badges" :show-small-badge="true" />
            </div>

            <div />

            <div
              class="baseprice d-flex justify-content-end ml-auto"
              :class="get(listing, 'currency_type') === 'gem' ? 'gem-base' : 'dollar-base'"
            >
              <span v-if="listing.base_price === 0">Free</span>
              <span v-else class="d-flex align-items-center">
                <inline-svg v-if="get(listing, 'currency_type') === 'gem'" src="/gem.svg" class="svg" />
                <span v-else>$</span>{{ compactNumber(listing.base_price || 0) }}
                {{ listing.base_price === 0 ? '' : 'each' }}</span
              >
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { MarketplaceListing } from '@/shared/types/static-types';
import MatureTag from '@/shared/components/MatureTag.vue';
import { compactNumber } from '@/shared/utils/number';
import CardPopover from './popovers/CardPopover.vue';
import { popovers } from '@/shared/native/popovers';
import { authStore } from '@/shared/pinia-store/auth';
import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';
import { resizeUpload } from '@/shared/utils/upload';
import { stripHtmlTags } from '@/shared/utils/string';
import { isMobileFn } from '@/apps/mobile/native/device';

const props = defineProps({
  openBlank: {
    type: Boolean,
    default: false,
  },
  listing: {
    type: Object,
    default: () => ({}),
  },
  isFollow: {
    type: Boolean,
    default: false,
  },
});

const openBlank = computed(() => props.openBlank as boolean);
const listing = computed(() => props.listing as MarketplaceListing);
const { user } = authStore();
const isNsfw = computed(() => listing.value.is_nsfw);

const badges = computed(() => {
  return get(listing.value?.seller?.customize_profile, 'badges') || [];
});
const isOwner = computed(() => user.value?.id === listing.value.seller?.id);
const id = computed(() => listing.value.id);
const openDropDown = async (ev: CustomEvent) => {
  await popovers.open(ev, CardPopover, { title: 'Marketplace Listing', id: id.value, value: 'marketplace' });
};
const goToListing = async (listing: MarketplaceListing) => {
  const router = useRouter();

  const route = { name: 'mp-listing-details', params: { slug: listing.slug } };

  const isMobile = await isMobileFn();

  if (openBlank.value && !isMobile) {
    const { href } = router.resolve(route);
    window.open(href, '_blank');
    return;
  }

  router.push(route);
};
const usernameColor = computed(() => {
  return listing.value?.seller?.customize_profile?.username_color || '';
});
</script>

<style lang="sass" scoped>
.base-content
  @media(max-width: 415px)
    max-width: calc(100% - 85px) !important
.svg
  width: 14px
  height: 14px
  margin-top: -2px
  margin-right: 1px
  @media(max-width: 330px)
    height: 12px
    width: 12px
.drop-down
  position: absolute
  z-index: 2
  right: 8px
  width: 18px
  height: 16px
  color: #FFF
  background: #0A0928E5
  font-size: 12px
  border-radius: 6px
  top: 10px
.tag-wrap
  top: 7px
  left: 7px
.tag
  min-width: 90px
  padding-left: 10px
  padding-right: 10px
  height: 20px
  border: 1.5px solid #ef3e3e
  color: #ef3e3e
  background: #0A0928E5
  font-size: 12px
  font-weight: bold
  border-radius: 10px
.img-height
  height: 180px !important
.mrkt-listing-height
  height: 250px !important
  min-width: 100% !important
.marketplace-listing
  min-width: 187px
  height: 205px
  border-radius: 12px
  box-shadow: 1px 1px 5px 0px rgba(33,65,99,0.19)
  overflow: hidden
  background: #FFF
  .image
    background: #ACBFD4
    height: 134px
    img
      width: 100%
      height: 100%
      object-fit: cover
  .name
    font-size: 12px
    color: #214163
    font-weight: bold
    span
      text-overflow: ellipsis
      width: 100%
      display: -webkit-box
      -webkit-line-clamp: 1
      max-height: 15px
      -webkit-box-orient: vertical
      overflow: hidden
  .name-height
    font-size: 18px !important
    span
      max-height: none !important
      font-size: 18px !important

  .description
    font-size: 10px !important
    color: #214163
    height: 15px
    overflow: hidden
    span
      text-overflow: ellipsis
      width: 100%
      display: -webkit-box
      -webkit-line-clamp: 1
      height: 15px
      -webkit-box-orient: vertical
      overflow: hidden
  .rest
    padding: 0 10px
    *
      font-size: 12px
      @media(max-width: 500px)
        font-size: 9px
  .baseprice
    font-weight: bold

    border-radius: 20px
    padding: 2px 7px 0px 7px
  .gem-base
    color: #00B4C5
    border: 2px solid #00B4C5
  .dollar-base
    color: #ae38e5
    border: 2px solid #ae38e5
.dark .gem-base
  border: 2px solid #fff
.dark .dollar-base
  border: 2px solid #fff
.user
  text-overflow: ellipsis
  width: 100%
  text-overflow: ellipsis

  height: 15px
  -webkit-box-orient: vertical
  overflow: hidden
  white-space: nowrap
</style>
